import React from "react";
import { useEffect, useState } from "react";
import { Box, TextField, Button } from '@mui/material'
import axios from 'axios';
import { Circles } from 'react-loader-spinner'
import { Formik } from 'formik'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery'
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { mockDataContacts } from "../../data/mockData"
import Header from "../../components/Header"
import '../../index.css'
let initialValue = {
    userType: "gatekeeper",
}
const currencies = [
    {
        value: 'owner',
        label: 'Owner',
    },
    {
        value: 'tenant',
        label: 'Tenant',
    }
];

const Contact = () => {
    let isNonMobile = useMediaQuery("(min-width:600px)");

    const [userData, setUserData] = useState()
    const [userType, setUserType] = useState('owner')
    const [loader, setLoader] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Details')
    const [userUnitDataState, setUserUnitDataState] = useState()
    const [css, setCss] = useState(false)
    const [message, setMessage] = useState('')
    const [messageLine, setMessageLine] = useState('')



    useEffect(() => {
        console.log("userType: " + userType)
        // setLoader(true)
        async function user() {
            const userData = await axios.post('https://devapi.homeonetechnologies.in/api/userList', {
                userType: `${userType}`
            });
            console.log('userData:of useEffetc ', userData.data.data);
            if (userData.data.status === 'SUCCESS') {
                setLoader(false)
                setUserData(userData.data.data);
                console.log('userData:owner ', userData);
            } else {
                console.log('userData:owner else part:')
            }
        }
        user()
    }, [userType])



    let column = [
        {
            field: "firstName",
            headerName: "Name",
            flex: 1,
        },
        {
            field: "lastName",
            headerName: "Last Name",
            flex: 1,
            // cellClassName: "name-column--cell"
        },
        {
            field: "gender",
            headerName: "Gender",
            flex: 1,
            // cellClassName: "name-column--cell"
        },
        {
            field: "email",
            headerName: "Email",
            flex: 2,
        },
        {
            field: "mobileNumber",
            headerName: "Phone Number",
            flex: 1,
        },
        {
            field: "userType",
            headerName: "User Type",
            flex: 1
            // headerAlign: "left",
            // align: "left"
        },
        {
            field: "flatNumber",
            headerName: "Unit Name",
            flex: 2
        },

        // {
        //   field: "access",
        //   headerName: "Access Level",
        //   flex: 1,
        //   renderCell: ({ row: { access } }) => {
        //     return (
        //       <Box
        //         width="60%"
        //         m="0 auto"
        //         p="5px"
        //         display="flex"
        //         justifyContent="center"
        //         alignItems="center"
        //         backgroundColor={
        //           access === "admin" ? "gray " : "green"
        //         }
        //         borderRadius="4px"
        //       >
        //         {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
        //         {access === "manager" && <SecurityOutlinedIcon />}
        //         {access === "user" && <LockOpenOutlinedIcon />}
        //         <Typography color="darkBlue" sx={{ ml: "5px" }}>
        //           {access}
        //         </Typography>
        //       </Box>
        //     )
        //   }
        // },
    ]

    let handleFormSubmit = (value) => {
        console.log("formSubmit:", value)

    }

    let handleCellClick = async (param) => {
        setLoader(true)
        console.log("param:", param)
        console.log("cellClick:", param.row.geraUserId)
        let userUnitData = await axios.post('https://devapi.homeonetechnologies.in/api/userUnitDetails', {
            geraUserId: `${param.row.geraUserId}`
        });
        console.log('userUnitData:Response', userUnitData.data.data);
        if (userUnitData.data.status === 'SUCCESS') {
            setLoader(false)
            setUserUnitDataState(userUnitData.data.data)
            console.log('userUnitDataState:after', userUnitDataState);
            setDialogTitle(`${param.row.firstName}  ${param.row.lastName !== null ? param.row.lastName : ''} (${param.row.userType})`)
            setDialogOpen(true);
        } else {
            console.log('userUnitData: else part:')
            setMessage(`${param.row.firstName} ${param.row.lastName} doesn't Have Unit In WOJ`)
            setCss(false);
            setMessageLine(true);
            setLoader(false)
            setTimeout(() => {
                setMessageLine(false);
            }, 4000)
        }

    }

    let handleUser = (event) => {
        if (event.target.value !== userType) {
            console.log("event:", event.target.value)
            console.log("loader:", loader)
            setLoader(true)
            setUserType(event.target.value)
        }
    }
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    return (

        <>
            {userData ? (

                <Box m="20px">
                    {loader ? (
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "210px" }}>
                            <Circles
                                height="80"
                                width="80"
                                color="#4fa94d"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                        </Box>
                    ) : (
                        <>
                            <Header title="User" subtitle="List of User" />
                            <Box m="40px 0 0 0" height="75vh"
                                sx={{
                                    "& .MuiDataGrid-root": {
                                        border: "none",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        borderBottom: "none",
                                    },
                                    "& .name-column--cell": {
                                        color: "darkBlue",
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        backgroundColor: "lightGray",
                                        borderBottom: "none",
                                    },
                                    "& .MuiDataGrid-virtualScrollbar": {
                                        backgroundColor: "gray",
                                    },
                                    "& .MuiDataGrid-footerContainer": {
                                        borderTop: "none",
                                        backgroundColor: "lightGray",
                                    },
                                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                        color: "black !important",
                                    },
                                }}
                            >
                                <Formik
                                    onSubmit={handleFormSubmit}
                                    initialValues={initialValue}
                                // validationSchema={validationSchemes}
                                >
                                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                                        <form id='formId' onSubmit={handleSubmit} >
                                            <Box display="grid" gap="30px" gridTemplateColumns="repeat(4,minmax(0,1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }, }}
                                            >
                                                <TextField
                                                    fullWidth
                                                    variant='filled'
                                                    id="userRole"
                                                    select
                                                    onBlur={handleBlur}
                                                    onChange={handleUser}
                                                    label='Select User Type'
                                                    value={values.userRole}
                                                    name='userRole'
                                                    error={!!touched.userRole && !!errors.userRole}
                                                    helperText={touched.userRole && errors.userRole}
                                                    sx={{ gridColumn: "span 4" }}
                                                >
                                                    {currencies.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Box>


                                        </form>
                                    )}
                                </Formik>
                                {messageLine && (
                                    <div className={css ? 'message' : 'message1'}>
                                        {message && message}
                                    </div>
                                )}
                                <DataGrid rows={userData} columns={column} components={{ Toolbar: GridToolbar }} onCellClick={handleCellClick} />
                                <Dialog open={dialogOpen} onClose={handleCloseDialog}>

                                    <DialogTitle>
                                        <div className="titleClass">
                                            {dialogTitle && dialogTitle}
                                        </div>
                                    </DialogTitle>
                                    <DialogContent>
                                        {/* Render the data in the dialog content */}
                                        {/* {selectedRowData && (    /////////api data
                                            <div>
                                                Render the data received from API
                                            </div>
                                        )} */}
                                        <div className="userDetalis">
                                            <table className="tableCss">
                                                <thead>
                                                    <tr className="thead">
                                                        <th>Building Name</th>
                                                        <th>Floor Name</th>
                                                        <th>Unit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {userUnitDataState && userUnitDataState.map((row, index) => (
                                                        <tr key={index} className="tdata">
                                                            <td></td>
                                                            <td>{row.buildingName}</td>
                                                            <td className="tdMiddle">{row.floorName}</td>
                                                            <td>{row.unit}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseDialog} color="primary">Close</Button>
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </>
                    )}

                </Box>



            ) : (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "210px" }}>
                    <Circles
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </Box>
            )}
        </>

    )
};

export default Contact;
