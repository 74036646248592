// import React from "react";
import firebase from 'firebase/compat/app';
// import { initializeApp } from 'firebase/app';
import 'firebase/compat/database';


const firebaseConfig = {
    apiKey: "AIzaSyBexB37wGt5HMrgYcswO8AyvwLaBWQlZy0",
    authDomain: "wificontrol-a94cc.firebaseapp.com",
    databaseURL: "https://wificontrol-a94cc.firebaseio.com",
    projectId: "wificontrol-a94cc",
    storageBucket: "wificontrol-a94cc.appspot.com",
    messagingSenderId: "986225208621",
    appId: "1:986225208621:web:539c7b2427c33da9d137a4",
    measurementId: "G-VLYEN7RZRM"
};

firebase.initializeApp(firebaseConfig);


export default firebase;