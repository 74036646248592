import React from "react";
import { Typography,Box } from "@mui/material";
const Header = ({title,subtitle}) => {
    return (
        <Box mb="30px">
            <Typography
                variant="h5"
                color="darkblue"
                fontWeight="bold"
                sx={{mb:"5px"}}
            >{title}</Typography>
            <Typography variant="h6" color="darkblue">{subtitle}</Typography>
        </Box>
    );
};

export default Header;  
