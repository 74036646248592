import { LOGIN_SUCCESS, LOGOUT, TOWER, DIALOG } from "./authType";


const initialState = {
    isAuthenticated: false,
    towerName: 'C',
    dialogeState: false
}



export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...state, isAuthenticated: true }
        case LOGOUT:
            return { ...state, isAuthenticated: false }

        case TOWER:
            return { ...state, towerName: action.payload }

        case DIALOG:
            return { ...state, dialogeState: action.payload }
        default:
            return state
    }

}