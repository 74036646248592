import { Box, Button, TextField } from '@mui/material'
import { Formik } from 'formik'
import axios from 'axios';
import { Circles } from 'react-loader-spinner'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as yup from 'yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Header from '../../components/Header'
import MenuItem from '@mui/material/MenuItem';
import '../../index.css'
import { useEffect, useState } from 'react';

let initialValue = {
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    maritalStatus: '',
    gender: '',
    dateOfBirth: '',
    userRole: '',
    pass: '',
    Password: '',
    tenantPassword: '',
    gateNumber: '',
    flatNumber: '',
    owerName: '',
    owerContact: '',
    owerflatNumber: '',
    ownerMaterialId: '',
    flatNumberOwer: ''
}
let phoneRegExp = /^[6-9]\d{9}$/;

let validationSchemes = yup.object().shape({

    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().email("Invalid email").required("required"),
    contact: yup.string().matches(phoneRegExp, "Phone number is not valid").required("required"),
    maritalStatus: yup.string().required("required"),
    gender: yup.string().required("required"),
    dateOfBirth: yup.string()
        .required('Date of birth is required')
        .matches(
            /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[0-2])\/\d{4}$/,
            'Invalid date format. Please use dd/mm/yyyy'
        )
        .test('valid-date', 'Invalid date', function (value) {
            if (!value) return true; // Handle empty value
            const parts = value.split('/');
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10);
            const year = parseInt(parts[2], 10);
            // Month is 0-indexed in Date, so subtract 1
            const date = new Date(year, month - 1, day);
            return (
                date.getDate() === day &&
                date.getMonth() === month - 1 &&
                date.getFullYear() === year
            );
        }),
    // pass: yup.string()
    //     .required('Password is required'),
    // Password: yup.string()
    //     .required('Password is required'),
    // tenantPassword: yup.string()
    //     .required('Password is required'),
    // owerName: yup.string().required("required"),
    // owerContact: yup.string().matches(phoneRegExp, "Phone number is not valid").required("required"),
    // owerflatNumber: yup.string()
    //     .matches(
    //         /^[A-Za-z]\s?-\s?\d{1,4}$/,
    //         'Flat number must be in the format: Letter - Number (e.g., A - 101)'
    //     )
    //     .required('Owner Flat number is required'),
    // ownerMaterialId: yup.string().required("required"),
    // flatNumberOwer: yup.string()
    //     .matches(
    //         /^[A-Za-z]\s?-\s?\d{1,4}$/,
    //         'Flat number must be in the format: Letter - Number (e.g., A - 101)'
    //     )
    //     .required('Flat number is required'),
    // startTime: yup.string().required('Start time is required').matches(
    //     /^(1[0-2]|0?[1-9]):([0-5][0-9]) (AM|PM)$/,
    //     'Start time must be in the format HH:MM AM/PM'
    // ),
    // endTime: yup.string().required('End time is required').matches(
    //     /^(1[0-2]|0?[1-9]):([0-5][0-9]) (AM|PM)$/,
    //     'End time must be in the format HH:MM AM/PM'
    // ).when('startTime', (startTime, schema) => {
    //     // Ensuring end time is after start time
    //     return schema.test({
    //         test: function (endTime) {
    //             if (typeof startTime !== 'string' || typeof endTime !== 'string') return true;

    //             const startTimeHour = parseInt(startTime.split(':')[0]);
    //             const startTimeMinute = parseInt(startTime.split(':')[1].split(' ')[0]);
    //             const startTimePeriod = startTime.split(' ')[1];

    //             const endTimeHour = parseInt(endTime.split(':')[0]);
    //             const endTimeMinute = parseInt(endTime.split(':')[1].split(' ')[0]);
    //             const endTimePeriod = endTime.split(' ')[1];

    //             // Convert hours to 24-hour format
    //             const startHour24 = (startTimePeriod === 'PM' && startTimeHour !== 12) ? startTimeHour + 12 : startTimeHour;
    //             const endHour24 = (endTimePeriod === 'PM' && endTimeHour !== 12) ? endTimeHour + 12 : endTimeHour;

    //             // Compare times
    //             if (startHour24 < endHour24 || (startHour24 === endHour24 && startTimeMinute < endTimeMinute)) {
    //                 return true;
    //             }
    //             return false;
    //         },
    //         message: 'End time must be after start time'
    //     });
    // }),


})

const currencies = [
    {
        value: 'Owner',
        label: 'Owner',
    },
    {
        value: 'Tenant',
        label: 'Tenant',
    },
    {
        value: 'GateKeeper',
        label: 'Gate Keeper',
    },
];
const materialStatus = [
    {
        value: 'unmarried',
        label: 'Unmarried',
    },
    {
        value: 'married',
        label: 'Married',
    }
]
const selectGender = [
    {
        value: 'male',
        label: 'Male',
    },
    {
        value: 'female',
        label: 'Female',
    }
]
const gate = [
    {
        value: 'gate1',
        label: 'Gate 1',
    },
    {
        value: 'gate2',
        label: 'Gate 2',
    },
    {
        value: 'gate3',
        label: 'Gate 3',
    },
];

let building
let floor
let unit
let existingUserInUnit
let selectedGeraBuildingUnitId

const Form = () => {
    let isNonMobile = useMediaQuery("(min-width:600px)");
    const [successMessage, setSuccessMessage] = useState(false)
    const [css, setCss] = useState(false)  //////////////css messages////////
    const [message, setMessage] = useState('')
    const [isDisabled, setIsDisabled] = useState(true);
    const [isDisabledUnit, setIsDisabledUnit] = useState(true);
    const [loader, setLoader] = useState(false)
    const [selectedGeraBuildingId, setSelectedGeraBuildingId] = useState()
    const [dialogOpen, DialogSetOpen] = useState(false);
    const [dailogSelected, setDailogSelected] = useState(false);
    const [changeOwnerShip, setChangeOwnerShip] = useState(false);

    const handleClickOpen = () => {

        DialogSetOpen(true);
        setDailogSelected(true);
    };

    const handleClose = () => {
        console.log("handleClose:")
        DialogSetOpen(false);

    };

    const handleCloseChangeOwner = () => {
        console.log("handleCloseChangeOwner:")
        setChangeOwnerShip(true)
        DialogSetOpen(false);
    }


    const handleBuilding = (event) => {
        console.log("geraBuildingId:", event.target.value)
        setSelectedGeraBuildingId(event.target.value)
        fetchFLoor(event.target.value)
    }
    const handleFloor = (event) => {
        console.log("geraFloorId", event.target.value)
        fetchUnit(event.target.value)
    }

    const handleUnit = (event) => {
        console.log("geraBuildingUnitId:", event.target.value)
        selectedGeraBuildingUnitId = event.target.value
        fetchUserDataWithUnit(event.target.value, 'owner')
    }

    const handleTenantUnit = (event) => {
        console.log("geraBuildingUnitId:tenant", event.target.value)
        selectedGeraBuildingUnitId = event.target.value
        fetchUserDataWithUnit(event.target.value, 'tenant')
    }

    let fetchUserDataWithUnit = async (buildingUnitId, usertype) => {
        const response = await axios.post('https://devapi.homeonetechnologies.in/api/getUserDetails', { geraBuildingUnitId: buildingUnitId, userType: usertype });
        console.log('Response:of UserUnitDetails:tenant', response.data);
        if (response.data.status === 'SUCCESS') {
            if (response.data.data.length > 0) {
                existingUserInUnit = response.data.data
                console.log("existingUserInUnit:tenant", existingUserInUnit)
                handleClickOpen()
            } else {
                console.log("dialoge not open user not found")
            }
        } else {
            console.log("response.data.message", response.data.message)
        }
    }

    let fetchFLoor = async (geraBuildingId) => {
        const response = await axios.post('https://devapi.homeonetechnologies.in/api/floorList', { geraBuildingId: geraBuildingId });
        // console.log('Response:of floor', response.data);
        if (response.data.status === 'SUCCESS') {
            floor = response.data.data
            console.log('inside floor successmessgae', floor)
            setIsDisabled(false)
        }
    }
    let fetchUnit = async (geraFloorId) => {
        console.log("selectedGeraBuildingId:", selectedGeraBuildingId)
        const response = await axios.post('https://devapi.homeonetechnologies.in/api/unitList', { geraBuildingId: selectedGeraBuildingId, geraFloorId: geraFloorId });
        // console.log('Response:of UNIT ', response.data);
        if (response.data.status === 'SUCCESS') {
            unit = response.data.data
            console.log('inside Unit successmessgae', unit)
            setIsDisabledUnit(false)
        }
    }


    useEffect(() => {
        async function fetchBuilding() {
            const response = await axios.post('https://devapi.homeonetechnologies.in/api/buildingList');
            console.log('Response:of useEffetc ', response.data);
            if (response.data.status === 'SUCCESS') {
                console.log('inside successmessgae')
                building = response.data.data
            }
        }
        fetchBuilding()

    }, [])


    const handleFormSubmit = async (value) => {
        console.log("----------value -----------", value)
        setLoader(true)
        if (value.userRole.toString().toLowerCase() === 'gatekeeper') {
            let gateKeeperPost = {
                "mobileNumber": value.contact.toString(),
                "email": value.email,
                "userType": value.userRole.toString().toLowerCase(),
                "firstName": value.firstName,
                "lastName": value.lastName,
                "dateOfBirth": value.dateOfBirth,
                "gender": value.gender,
                "password": value.pass,
                "gateId": value.gate,
                "marritalStatus": value.maritalStatus,
            }
            try {
                const response = await axios.post('https://devapi.homeonetechnologies.in/api/createUser', gateKeeperPost);
                console.log('Response:', response.data, response.status);
                if (response.status === 200) {
                    setCss(true)
                    setMessage('Gatekeeper Created Successfully')
                    setLoader(false)
                    setSuccessMessage(true)
                    setTimeout(() => {
                        setSuccessMessage(false)
                    }, 3000);
                }

            } catch (error) {
                console.log("error--------", error);
                if (error.response.status === 409) {
                    setLoader(false)
                    setCss(false)
                    setMessage('Gatekeeper Already Exists')
                    setSuccessMessage(true)
                    setTimeout(() => {
                        setSuccessMessage(false)
                    }, 4000);
                } else {
                    setLoader(false)
                    setMessage('An error occurred. Please try again later')
                    setCss(false)
                    setSuccessMessage(true)
                    setTimeout(() => {
                        setSuccessMessage(false)
                    }, 4000);
                }
            }
        } else if (value.userRole.toString().toLowerCase() === 'owner') {
            if (!dailogSelected) {
                console.log(' owner: no dialog open ')
                let materialId
                let flatNumber
                let buildingUnitId
                for (let index in unit) {
                    if (unit[index].geraBuildingUnitId === selectedGeraBuildingUnitId) {
                        materialId = unit[index].materialId
                        flatNumber = unit[index].unit
                        buildingUnitId = unit[index].geraBuildingUnitId
                    }
                }
                console.log("materialId, flatNumber, buildingUnitId", materialId, flatNumber, buildingUnitId)
                //////////////////first call the gera create user API//////////////////
                let geraPost = {
                    "userRoleId": 9,
                    "userName": value.firstName.toString(),
                    "password": value.Password,
                    "firstName": value.firstName.toString(),
                    "lastName": value.lastName.toString(),
                    "mobileNumber": value.contact.toString(),
                    "emailId": value.email,
                    "parentUserId": 0,
                    "marritalStatus": value.maritalStatus,
                    "dateofBirth": null,
                    "gender": value.gender.toString(),
                    "photoFile": "",
                    "unitMaterialNo": "00000000000" + materialId
                }
                const geraResponse = await axios.post('https://geraworld3dev.azurewebsites.net/api/integration/AddUser', geraPost);
                console.log('geraResponse:Ap', geraResponse.data);
                if (geraResponse.status === 200 && geraResponse.data.status === true) {
                    let returngeraUserId = geraResponse.data.data.id
                    console.log("useradded successfully at gera side", returngeraUserId)

                    ////////////// "dialog not open create new owner"
                    let postData = {
                        "geraUserName": value.firstName.toString(),
                        "firstName": value.firstName.toString(),
                        "lastName": value.lastName.toString(),
                        "mobileNumber": value.contact.toString(),
                        "email": value.email,
                        "password": value.Password,
                        "dateOfBirth": value.dateOfBirth,
                        "gender": value.gender,
                        "userType": "owner",
                        "flatNumber": flatNumber,
                        "geraUserId": returngeraUserId.toString(),
                        "userRoleId": "9",
                        "parentUserId": "0",
                        "marritalStatus": value.maritalStatus.toString(),
                        "buildingUnitId": buildingUnitId.toString(),
                        "materialId": materialId
                    }
                    try {
                        const response = await axios.post('https://devapi.homeonetechnologies.in/api/addOwner', postData);
                        console.log('Response:HomeoneAPI', response.data, response.status);
                        if (response.status === 200) {
                            let userUnitMappingData = {
                                "geraUserId": returngeraUserId.toString(),
                                "geraBuildingUnitId": buildingUnitId.toString()
                            }
                            const userUnitMappigResponse = await axios.post('https://devapi.homeonetechnologies.in/api/userUnitMapping', userUnitMappingData);
                            if (userUnitMappigResponse.status === 200) {
                                setLoader(false)
                                setCss(true)
                                setMessage(response.data.message)
                                setSuccessMessage(true)
                                setTimeout(() => {
                                    setSuccessMessage(false)
                                }, 5000);
                            }
                        }
                    } catch (error) {
                        setLoader(false)
                        setMessage('An error occurred. Please try again later')
                        setCss(false)
                        setSuccessMessage(true)
                        setTimeout(() => {
                            setSuccessMessage(false)
                        }, 4000);
                    }
                } else if (!geraResponse.data.status) {
                    console.log("geraErrorMessage", geraResponse.data.message)
                    setLoader(false)
                    setMessage("" + geraResponse.data.message)
                    setSuccessMessage(true)
                    setTimeout(() => {
                        setSuccessMessage(false)
                    }, 4000);
                }


            } else {
                if (changeOwnerShip) {
                    setLoader(false)
                    console.log("changeOwnerShip:", changeOwnerShip)
                    console.log(' owner: yes option selected on dialog')


                } else {
                    console.log(' owner: no option selected on dialog')

                    setLoader(false)
                    console.log("empty unit", changeOwnerShip)
                    setCss(false)
                    setMessage('To select an unoccupied unit for adding a user Or Change OwnerShip')
                    setSuccessMessage(true)
                    setTimeout(() => {
                        setSuccessMessage(false)
                    }, 5000);
                }
            }
        } else if (value.userRole.toString().toLowerCase() === 'tenant') {
            if (!dailogSelected) {
                console.log(' tenant: no dialog open ')
                let materialId
                let flatNumber
                let buildingUnitId
                let parentUserId
                for (let index in unit) {
                    if (unit[index].geraBuildingUnitId === selectedGeraBuildingUnitId) {
                        materialId = unit[index].materialId
                        flatNumber = unit[index].unit
                        buildingUnitId = unit[index].geraBuildingUnitId
                    }
                }

                /////////////////////// get parentUserId from api ///////////////////

                const response = await axios.post('https://devapi.homeonetechnologies.in/api/getUserDetails', { geraBuildingUnitId: selectedGeraBuildingUnitId, userType: 'owner' });
                console.log('owner of tenant details response:', response.data);
                if (response.data.status === 'SUCCESS') {
                    if (response.data.data.length > 0) {
                        console.log("existingUserInUnit:tenant", response.data.data)
                        // geraUserId: 5482
                        parentUserId = response.data.data[0].geraUserId
                    } else {
                        console.log(`owner not fount of tenant of Unit ${selectedGeraBuildingUnitId}`)
                    }
                } else {
                    console.log("response.data.message", response.data.message)
                }

                //////////////////first call the gera create user API//////////////////
                console.log("materialId, flatNumber, buildingUnitId,parentUserId", materialId, flatNumber, buildingUnitId, parentUserId)

                let geraPost = {
                    "userRoleId": 11,
                    "userName": value.firstName.toString(),
                    "password": value.tenantPassword.toString(),
                    "firstName": value.firstName.toString(),
                    "lastName": value.lastName.toString(),
                    "mobileNumber": value.contact.toString(),
                    "emailId": value.email,
                    "parentUserId": parentUserId,
                    "marritalStatus": value.maritalStatus,
                    "dateofBirth": null,
                    "gender": value.gender.toString(),
                    "photoFile": "",
                    "unitMaterialNo": "00000000000" + materialId
                }
                console.log("geraPost:", geraPost)
                let geraResponseTenant = await axios.post('https://geraworld3dev.azurewebsites.net/api/integration/AddUser', geraPost);
                console.log('geraResponseTenant:Ap', geraResponseTenant.data);
                if (geraResponseTenant.status === 200 && geraResponseTenant.data.status === true) {
                    let returngeraUserId = geraResponseTenant.data.data.id
                    console.log("useradded successfully at gera side", returngeraUserId)

                    ////////////// "dialog not open create new owner"
                    let postData = {
                        "geraUserName": value.firstName.toString(),
                        "firstName": value.firstName.toString(),
                        "lastName": value.lastName.toString(),
                        "mobileNumber": value.contact.toString(),
                        "email": value.email,
                        "password": value.Password,
                        "dateOfBirth": value.dateOfBirth,
                        "gender": value.gender,
                        "userType": "tenant",
                        "flatNumber": flatNumber,
                        "geraUserId": returngeraUserId.toString(),
                        "userRoleId": "11",
                        "parentUserId": parentUserId,
                        "marritalStatus": value.maritalStatus.toString(),
                        "buildingUnitId": buildingUnitId.toString(),
                        "materialId": materialId
                    }
                    try {
                        const response = await axios.post('https://devapi.homeonetechnologies.in/api/addOwner', postData);
                        console.log('Response:HomeoneAPI', response.data, response.status);
                        if (response.status === 200) {
                            let userUnitMappingData = {
                                "geraUserId": returngeraUserId.toString(),
                                "geraBuildingUnitId": buildingUnitId.toString()
                            }
                            const userUnitMappigResponse = await axios.post('https://devapi.homeonetechnologies.in/api/userUnitMapping', userUnitMappingData);
                            if (userUnitMappigResponse.status === 200) {
                                setLoader(false)
                                setCss(true)
                                setMessage(response.data.message)
                                setSuccessMessage(true)
                                setTimeout(() => {
                                    setSuccessMessage(false)
                                }, 5000);
                            }
                        }
                    } catch (error) {
                        setLoader(false)
                        setMessage('An error occurred. Please try again later')
                        setCss(false)
                        setSuccessMessage(true)
                        setTimeout(() => {
                            setSuccessMessage(false)
                        }, 4000);
                    }
                } else if (!geraResponseTenant.data.status) {
                    console.log("geraErrorMessage", geraResponseTenant.data.message)
                    setLoader(false)
                    setMessage("" + geraResponseTenant.data.message)
                    setSuccessMessage(true)
                    setTimeout(() => {
                        setSuccessMessage(false)
                    }, 4000);
                } else {
                    console.log("else part",)
                }

            } else {

                if (changeOwnerShip) {            /////////// change owership only flag . changeOwnerShip = change tenant
                    setLoader(false)
                    console.log("change-tenant:", changeOwnerShip)
                    console.log('tenant: yes option selected on dialog')

                } else {
                    console.log(' tenant: no option selected on dialog')

                    setLoader(false)
                    console.log("empty unit", changeOwnerShip)
                    setCss(false)
                    setMessage('To select an unoccupied unit for adding a Tenant Or change Tenant')
                    setSuccessMessage(true)
                    setTimeout(() => {
                        setSuccessMessage(false)
                    }, 5000);
                }
            }
        }

        // let postData = {
        //     "mobileNumber": value.contact.toString(),
        //     "email": value.email,
        //     "userType": value.userRole.toString().toLowerCase(),
        //     "firstName": value.firstName,
        //     "lastName": value.lastName,
        //     "dateOfBirth": value.dateOfBirth,
        //     "gender": value.gender,
        //     "password": value.pass,
        //     "gateId": value.gate,
        //     "marritalStatus": value.maritalStatus,
        // }
        // if (changeOwnerShip) {
        //     setLoader(true)

        //     console.log("changeOwnerShip:", changeOwnerShip)
        //     ///////////////////////// loginc to change the ownership ////////////////////
        //     // try {
        //     //     const response = await axios.post('https://devapi.homeonetechnologies.in/api/createUser', postData);
        //     //     console.log('Response:', response.data, response.status);
        //     //     if (response.status === 200) {
        //     //         setLoader(false)
        //     //         setSuccessMessage(true)
        //     //         setTimeout(() => {
        //     //             setSuccessMessage(false)
        //     //         }, 3000);
        //     //     }
        //     //     // Handle success, update UI, etc.0
        //     // } catch (error) {
        //     //     console.log("error--------", error);
        //     //     console.error('Error:', error);
        //     // }
        // }
        // if (dialogNoValue) {
        //     setLoader(true)
        //     console.log("run no part")
        //     ////////////////// adding new user ////////////////////////
        //     try {
        //         console.log("unit:inside the create user:", unit)
        //         let materialId
        //         for (let index in unit) {
        //             if (unit[index].geraBuildingUnitId === selectedGeraBuildingUnitId) {
        //                 materialId = unit[index].materialId
        //             }
        //         }
        //         console.log("materialId:", materialId)
        //         let post = {
        //             "userRoleId": 9,
        //             "userName": value.firstName.toString(),
        //             "password": value.Password,
        //             "firstName": value.firstName.toString(),
        //             "lastName": value.lastName.toString(),
        //             "mobileNumber": value.contact.toString(),
        //             "emailId": value.email,
        //             "parentUserId": 0,
        //             "marritalStatus": value.maritalStatus,
        //             "dateofBirth": null,
        //             "gender": value.gender.toString(),
        //             "photoFile": "",
        //             "unitMaterialNo": "00000000000" + materialId
        //         }
        //         const geraResponse = await axios.post('https://geraworld3dev.azurewebsites.net/api/integration/AddUser', post);
        //         console.log('geraResponse:Gera', geraResponse.data);
        //         if (geraResponse.status === 200 && geraResponse.data.status === true) {
        //             let geraUserData = geraResponse.data.id
        //             console.log("useradded successfully", geraResponse.data.id, geraUserData, value.ownerUnit)
        //             post['geraUserId'] = geraUserData
        //             post['flatNumber'] = value.ownerUnit.toString()
        //             post['email'] = value.email
        //             post['dateofBirth'] = value.dateofBirth
        //             post['userType'] = "owner"

        //             const response = await axios.post('https://devapi.homeonetechnologies.in/api/createUser', postData);
        //             console.log('Response:', response.data, response.status);
        //             if (response.status === 200) {
        //                 setLoader(false)
        //                 setSuccessMessage(true)
        //                 setTimeout(() => {
        //                     setSuccessMessage(false)
        //                 }, 3000);
        //             }
        //         }
        //     } catch (error) {
        //         console.log("error--------", error);
        //     }
        // } else {
        //     setSuccessMessage1(true)
        //     setTimeout(() => {
        //         setSuccessMessage1(false)
        //     }, 5000);
        // }
    }
    return (

        <Box m="20px">
            <Header title="CREATE USER" subtitle="Create a New User Profile" />
            {loader ? (


                <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "210px" }}>
                    <Circles
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </Box>) : (
                <>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValue}
                        validationSchema={validationSchemes}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form id='formId' onSubmit={handleSubmit} >
                                {successMessage && (
                                    <div id='msgId' className={css ? 'message' : 'message1'}>
                                        {message && message}
                                    </div>
                                )}

                                <Box display="grid" gap="30px" gridTemplateColumns="repeat(4,minmax(0,1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }, }}
                                >
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='firstName'
                                        type='text'
                                        label='First Name'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.firstName}
                                        name='firstName'
                                        error={!!touched.firstName && !!errors.firstName}
                                        helperText={touched.firstName && errors.firstName}
                                        sx={{ gridColumn: "span 2" }}

                                    />
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='lastName'
                                        type='text'
                                        label='last Name'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.lastName}
                                        name='lastName'
                                        error={!!touched.lastName && !!errors.lastName}
                                        helperText={touched.lastName && errors.lastName}
                                        sx={{ gridColumn: "span 2" }}

                                    />
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='email'
                                        type='email'
                                        label='Email Address'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name='email'
                                        error={!!touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                        sx={{ gridColumn: "span 2" }}

                                    />
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='contact'
                                        type='number'
                                        label='Contact'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.contact}
                                        name='contact'
                                        error={!!touched.contact && !!errors.contact}
                                        helperText={touched.contact && errors.contact}
                                        sx={{ gridColumn: "span 2" }}

                                    />
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='maritalStatus'
                                        select
                                        label='Marital Status'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.maritalStatus}
                                        name='maritalStatus'
                                        error={!!touched.maritalStatus && !!errors.maritalStatus}
                                        helperText={touched.maritalStatus && errors.maritalStatus}
                                        sx={{ gridColumn: "span 2" }}
                                    >
                                        {materialStatus.map((element) => (
                                            <MenuItem key={element.value} value={element.value}>{element.label}</MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='gender'
                                        select
                                        label='Gender'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.gender}
                                        name='gender'
                                        error={!!touched.gender && !!errors.gender}
                                        helperText={touched.gender && errors.gender}
                                        sx={{ gridColumn: "span 2" }}

                                    >
                                        {selectGender.map((gender) => (
                                            <MenuItem key={gender.value} value={gender.value}>
                                                {gender.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id='dateOfBirth'
                                        type='text'
                                        label='D.O.B'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.dateOfBirth}
                                        name='dateOfBirth'
                                        error={!!touched.dateOfBirth && !!errors.dateOfBirth}
                                        helperText={touched.dateOfBirth && errors.dateOfBirth}
                                        sx={{ gridColumn: "span 2" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant='filled'
                                        id="userRole"
                                        select
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label='Select User Type'
                                        value={values.userRole}
                                        name='userRole'
                                        error={!!touched.userRole && !!errors.userRole}
                                        helperText={touched.userRole && errors.userRole}
                                        sx={{ gridColumn: "span 2" }}
                                    >
                                        {currencies.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    {values.userRole === 'GateKeeper' && (
                                        <>
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id="gateNumber"
                                                select
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label='Assing Gate Number'
                                                value={values.gateNumber}
                                                name='gateNumber'
                                                error={!!touched.gateNumber && !!errors.gateNumber}
                                                helperText={touched.gateNumber && errors.gateNumber}
                                                sx={{ gridColumn: "span 2" }}
                                            >
                                                {gate.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id='pass'
                                                type='text'
                                                label='Assing Password'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.pass}
                                                name='pass'
                                                error={!!touched.pass && !!errors.pass}
                                                helperText={touched.pass && errors.pass}
                                                sx={{ gridColumn: "span 2" }}
                                            />
                                            {/* <TextField
                                                fullWidth
                                                variant='filled'
                                                id='startTime'
                                                type='text'
                                                label='Assing Start Time'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.startTime}
                                                name='startTime'
                                                error={!!touched.startTime && !!errors.startTime}
                                                helperText={touched.startTime && errors.startTime}
                                                sx={{ gridColumn: "span 2" }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id='endTime'
                                                type='text'
                                                label='Assing End Time'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.endTime}
                                                // name='endTime'
                                                error={!!touched.endTime && !!errors.endTime}
                                                helperText={touched.endTime && errors.endTime}
                                                sx={{ gridColumn: "span 2" }}
                                            /> */}
                                        </>
                                    )}

                                    {values.userRole === 'Tenant' && (
                                        <>
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id='tenantPassword'
                                                type='text'
                                                label='Password'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.tenantPassword}
                                                name='tenantPassword'
                                                error={!!touched.tenantPassword && !!errors.tenantPassword}
                                                helperText={touched.tenantPassword && errors.tenantPassword}

                                                sx={{ gridColumn: "span 2" }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id='tenantBuiling'
                                                select
                                                label='Select Building'
                                                onBlur={handleBlur}
                                                onChange={handleBuilding}
                                                value={values.tenantBuiling}
                                                name='tenantBuiling'
                                                error={!!touched.tenantBuiling && !!errors.tenantBuiling}
                                                helperText={touched.tenantBuiling && errors.tenantBuiling}
                                                sx={{ gridColumn: "span 2" }}
                                            >
                                                {building && building.map((element) => (
                                                    <MenuItem key={element.geraBuildingId} value={element.geraBuildingId}>      {element.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id='tenantFloor'
                                                select
                                                label='Select Floor'
                                                onBlur={handleBlur}
                                                onChange={handleFloor}
                                                value={values.tenantFloor}
                                                name='tenantFloor'
                                                error={!!touched.tenantFloor && !!errors.tenantFloor}
                                                helperText={touched.tenantFloor && errors.tenantFloor}
                                                sx={{ gridColumn: "span 2" }}
                                                disabled={isDisabled}
                                            >
                                                {floor && floor.map((floorData) => (
                                                    <MenuItem key={floorData.geraFloorId} value={floorData.geraFloorId}>{floorData.name}</MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id='tenantUnit'
                                                select
                                                label='select Unit'
                                                onBlur={handleBlur}
                                                onChange={handleTenantUnit}
                                                value={values.tenantUnit}
                                                name='tenantUnit'
                                                error={!!touched.tenantUnit && !!errors.tenantUnit}
                                                helperText={touched.tenantUnit && errors.tenantUnit}
                                                sx={{ gridColumn: "span 2" }}
                                                disabled={isDisabledUnit}
                                            >
                                                {unit && unit.map((unitId) => (
                                                    <MenuItem key={unitId.geraBuildingUnitId} value={unitId.geraBuildingUnitId}>{unitId.unit}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </>
                                    )}

                                    {values.userRole === 'Owner' && (
                                        <>
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id='Password'
                                                type='text'
                                                label='Password'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.Password}
                                                name='Password'
                                                error={!!touched.Password && !!errors.Password}
                                                helperText={touched.Password && errors.Password}

                                                sx={{ gridColumn: "span 2" }}
                                            />
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id='ownerBuiling'
                                                select
                                                label='Select Building'
                                                onBlur={handleBlur}
                                                onChange={handleBuilding}
                                                value={values.ownerBuiling}
                                                name='ownerBuiling'
                                                error={!!touched.ownerBuiling && !!errors.ownerBuiling}
                                                helperText={touched.ownerBuiling && errors.ownerBuiling}
                                                sx={{ gridColumn: "span 2" }}
                                            >
                                                {building && building.map((element) => (
                                                    <MenuItem key={element.geraBuildingId} value={element.geraBuildingId}>      {element.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id='ownerFloor'
                                                select
                                                label='Select Floor'
                                                onBlur={handleBlur}
                                                onChange={handleFloor}
                                                value={values.ownerFloor}
                                                name='ownerFloor'
                                                error={!!touched.ownerFloor && !!errors.ownerFloor}
                                                helperText={touched.ownerFloor && errors.ownerFloor}
                                                sx={{ gridColumn: "span 2" }}
                                                disabled={isDisabled}
                                            >
                                                {floor && floor.map((floorData) => (
                                                    <MenuItem key={floorData.geraFloorId} value={floorData.geraFloorId}>{floorData.name}</MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                fullWidth
                                                variant='filled'
                                                id='ownerUnit'
                                                select
                                                label='select Unit'
                                                onBlur={handleBlur}
                                                onChange={handleUnit}
                                                value={values.ownerUnit}
                                                name='ownerUnit'
                                                error={!!touched.ownerUnit && !!errors.ownerUnit}
                                                helperText={touched.ownerUnit && errors.ownerUnit}
                                                sx={{ gridColumn: "span 2" }}
                                                disabled={isDisabledUnit}
                                            >
                                                {unit && unit.map((unitId) => (
                                                    <MenuItem key={unitId.geraBuildingUnitId} value={unitId.geraBuildingUnitId}>{unitId.unit}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </>
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="end" mt="20px">
                                    <Button type='submit' color="secondary" variant='contained'>
                                        CREATE NEW USER
                                    </Button>
                                </Box>

                            </form>
                        )}
                    </Formik>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"The flat is already occupied. Would you like to transfer ownership?"}
                        </DialogTitle>
                        {/* <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Let Google help apps determine location. This means sending anonymous
                                location data to Google, even when no apps are running.
                            </DialogContentText>
                        </DialogContent> */}
                        <DialogActions>
                            <Button color="secondary" variant='contained' onClick={handleCloseChangeOwner}>Yes</Button>
                            <Button color="secondary" variant='contained' onClick={handleClose}>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )
            }

        </Box >
    )

}

export default Form;



// import * as React from 'react';
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

// export default function AlertDialog() {
//     const [open, setOpen] = React.useState(false);

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     return (
//         <React.Fragment>
//             <Button variant="outlined" onClick={handleClickOpen}>
//                 Open alert dialog
//             </Button>
//             <Dialog
//                 open={open}
//                 onClose={handleClose}
//                 aria-labelledby="alert-dialog-title"
//                 aria-describedby="alert-dialog-description"
//             >
//                 <DialogTitle id="alert-dialog-title">
//                     {"Use Google's location service?"}
//                 </DialogTitle>
//                 <DialogContent>
//                     <DialogContentText id="alert-dialog-description">
//                         Let Google help apps determine location. This means sending anonymous
//                         location data to Google, even when no apps are running.
//                     </DialogContentText>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleClose}>Disagree</Button>
//                     <Button onClick={handleClose} autoFocus>
//                         Agree
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </React.Fragment>
//     );
// }
