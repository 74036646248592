import React from "react";
// import 'react-pro-sidebar/dist/css/styles.css';

import { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material"
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
// import { style } from "@mui/system";
import '../../index.css'

let Item = ({ title, to, selected, setSelected, icon }) => {

    return (
        <Box sx={{
            "&:hover .css-ahj2mt-MuiTypography-root": { color: "darkblue !important" },
            "&:hover .css-i4bv87-MuiSvgIcon-root": { color: "darkblue !important" },
            "&:hover .css-9l3uo3": { color: "darkblue !important" },

            // css-9l3uo3
            // "&:hover .iconCss": { color: "black !important" }
        }}>
            <MenuItem
                active={selected === title}
                style={{ color: "darkblue" }}
                icon={icon}
                onClick={() => setSelected(title)}

            >
                <Link to={to} style={{ textDecoration: 'none' }}><Typography>{title}</Typography></Link>
            </MenuItem >
        </Box>

    )

}

const SideBar = () => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [selected, setSelected] = useState('Dashboard')




    return (
        <Box
            sx={{
                "& .pro - slidbar - inner": { background: "red !important" },
                "& .pro -icon-wrapper": { backgroundColor: "green !important" },
                "& .pro-inner-item": { padding: "5px 35px 5px 20px !important" },
                "& .pro-inner-item:hover": { color: "#868dfb !important" },
                "& .pro-menu-item.active": { color: "#6870fa !important" },
            }}
            display="flex"
            // borderRadius="3px"
            backgroundColor="lightgrey"
        >
            <Sidebar collapsed={isCollapsed} backgroundColor="" >
                <Menu iconShape="square">
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{ margin: "10px 0 20px 0" }}
                    >
                        {!isCollapsed && (
                            <Box display="flex" justifyContent="space-between" alignItems="center" ml="15px" sx={{


                            }}>
                                <Typography variant="h5" color="grey">
                                    ADMINS
                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>

                        )}
                    </MenuItem>



                    {/* userImg */}
                    {!isCollapsed && (
                        <Box backgroundColor="">
                            <Box display="flex" justifyContent="center" alignItems="center" backgroundColor="">
                                <img
                                    alt="IconIMg"
                                    width="25%"
                                    // height="100%"
                                    style={{ cursor: 'pointer', borderRadius: '50%' }}
                                    src={`../../assets/download.png`}
                                />
                            </Box>
                            <Box textAlign="center">
                                <Typography variant="h5" color="darkBlue" fontWeight="bold" sx={{ m: "10px 0 0 0" }}>Gera</Typography>
                                {/* <Typography variant="h6" color="gray">Full Stack Dev</Typography> */}
                            </Box>
                        </Box>
                    )}


                    {/* menu items  */}
                    <Box paddingLeft={isCollapsed ? undefined : "10%"}
                        sx={{
                            "&:hover .iconCss": {
                                color: "darkblue !important",
                                border: "1.5px solid darkblue !important",
                                borderRadius: "50%",
                                padding: "3px"
                            }
                        }}
                    >
                        {/* <Item
                            title="Dashboard"
                            to="/"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<HomeOutlinedIcon />}
                        /> */}
                        {/* <Typography color="darkblue" style={{ m: "15px 0 5px 20px" }}>
                            Data
                        </Typography> */}
                        <Item
                            title="Profile Form"
                            to="/from"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<PersonOutlinedIcon className="iconCss" />}
                        />
                        <Item
                            title="GateKeeper"
                            to="/gateKeeper"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<PeopleOutlinedIcon className="iconCss" />}
                        />
                        <Item
                            title="User"
                            to="/user"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<ContactsOutlinedIcon className="iconCss" />}
                        />
                        {/* <Item
                            title="Invoices Balance"
                            to="/invoices"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<ReceiptOutlinedIcon />}
                        />
                        <Typography color="darkblue" style={{ m: "15px 0 5px 20px" }}>
                            Pages
                        </Typography> */}

                        {/* <Item
                            title="Calendar"
                            to="/calender"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<CalendarTodayOutlinedIcon />}
                        />
                        <Item
                            title="FAQ page"
                            to="/faq"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<HelpOutlinedIcon />}
                        />
                        <Typography color="darkblue" style={{ m: "15px 0 5px 20px" }}>
                            Chart
                        </Typography>
                        <Item
                            title="Bar chart"
                            to="/bar"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<BarChartOutlinedIcon />}
                        />
                        <Item
                            title="Pie chart"
                            to="/pie"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<PieChartOutlinedIcon />}
                        />
                        <Item
                            title="Line chart"
                            to="/line"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<TimelineOutlinedIcon />}
                        />
                        <Item
                            title="Geography chart"
                            to="/=geography"
                            selected={selected}
                            setSelected={setSelected}
                            icon={<MapOutlinedIcon />}
                        /> */}

                    </Box>
                </Menu>


            </Sidebar>

        </Box >

    )
};

export default SideBar;
