import { LOGIN_SUCCESS, LOGOUT, TOWER, DIALOG } from './authType'


export const loginSuccess = () => ({
    type: LOGIN_SUCCESS
})

export const logOut = () => ({
    type: LOGOUT
})

export const towerNameChanges = (towerN) => ({
    type: TOWER,
    payload: towerN
})

export const dailogeOpenClose = (dialogState) => ({
    type: DIALOG,
    paylod: dialogState

})