import { Routes, Route, redirect, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProtectedRoute = ({ element: Element, ...rest }) => {
    const isAuthenticated = useSelector((state) => { 
        return state.auth.isAuthenticated
    })

    console.log("isAuthenticated:", isAuthenticated)
    // const isAuthenticated = false;
    return (
        isAuthenticated ? <Outlet /> : <Navigate to='/' />
    )
}

