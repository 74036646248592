import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Box, Button, TextField } from '@mui/material'

import { Formik } from 'formik'
import { Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Circles } from 'react-loader-spinner'
import useMediaQuery from '@mui/material/useMediaQuery'
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import { mockDataTeam } from "../../data/mockData"
import MenuItem from '@mui/material/MenuItem';
import Header from "../../components/Header"
let initialValue = {
  userType: "gatekeeper",
}
const currencies = [
  {
    value: 'owner',
    label: 'Owner',
  },
  {
    value: 'tenant',
    label: 'Tenant',
  },
  {
    value: 'gateKeeper',
    label: 'Gate Keeper',
  },
];

const Team = () => {
  // let isNonMobile = useMediaQuery("(min-width:600px)");

  const [gateKeeperData, setGateKeeperData] = useState()
  // const [loader, setLoader] = useState(false)

  useEffect(() => {
    async function gateKeeper1() {
      const gateKeeperData = await axios.post('https://devapi.homeonetechnologies.in/api/gateKeeperList');
      // console.log('gateKeeperData:of useEffetc ', gateKeeperData.data.data);
      if (gateKeeperData.data.status === 'SUCCESS') {
        //
        setGateKeeperData(gateKeeperData.data.data);
        console.log('gateKeeper ', gateKeeperData);
      } else {
        console.log('gateKeeper else part:')
      }
    }
    gateKeeper1()
  }, [])

  let column = [
    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 2,
      // cellClassName: "name-column--cell"
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      // cellClassName: "name-column--cell"
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "mobileNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "password",
      headerName: "Assigned Password",
      flex: 1
      // headerAlign: "left",
      // align: "left"
    },
    {
      field: "dateOfBirth",
      headerName: "Date Of Birth",
      flex: 1
    },
    {
      field: "marritalStatus",
      headerName: "Marrital Status",
      flex: 1
    }
    // {
    //   field: "access",
    //   headerName: "Access Level",
    //   flex: 1,
    //   renderCell: ({ row: { access } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         alignItems="center"
    //         backgroundColor={
    //           access === "admin" ? "gray " : "green"
    //         }
    //         borderRadius="4px"
    //       >
    //         {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
    //         {access === "manager" && <SecurityOutlinedIcon />}
    //         {access === "user" && <LockOpenOutlinedIcon />}
    //         <Typography color="darkBlue" sx={{ ml: "5px" }}>
    //           {access}
    //         </Typography>
    //       </Box>
    //     )
    //   }
    // },
  ]

  // let handleFormSubmit = (value) => {
  //   console.log("formSubmit", value)

  // }

  return (

    <>
      {gateKeeperData ?
        (
          <Box m="20px">
            <Header title="Gate Keeper" subtitle="Managing The Gate Keeper Members" />
            <Box m="40px 0 0 0" height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: "darkBlue",
                },
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "lightGray",
                  borderBottom: "none",
                },
                "& .MuiDataGrid-virtualScrollbar": {
                  backgroundColor: "gray",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "lightGray",
                  borderRadius: "5px"
                }
              }}
            >
              {/* <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValue}
              // validationSchema={validationSchemes}
              >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                  <form id='formId' onSubmit={handleSubmit} >
                    <Box display="grid" gap="30px" gridTemplateColumns="repeat(4,minmax(0,1fr))" sx={{ "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }, }}
                    >
                      <TextField
                        fullWidth
                        variant='filled'
                        id="userRole"
                        select
                        onBlur={handleBlur}
                        onChange={handleChange}
                        label='Select User Type'
                        value={values.userRole}
                        name='userRole'
                        error={!!touched.userRole && !!errors.userRole}
                        helperText={touched.userRole && errors.userRole}
                        sx={{ gridColumn: "span 4" }}
                      >
                        {currencies.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>


                  </form>
                )}
              </Formik> */}
              <Box>
                <DataGrid rows={gateKeeperData && gateKeeperData} columns={column} components={{ Toolbar: GridToolbar }} />
              </Box>

            </Box>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: "210px" }}>
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </Box>
        )}
    </>

  )
};

export default Team;
