import { Routes, Route } from "react-router-dom";
import TopBar from "./scence/global/TopBar";
import SideBar from "./scence/global/SideBar";
import Dashboard from "./scence/dashboard";
import Team from "./scence/team";
import Contact from "./scence/contact";
import Invoice from "./scence/invoices";
import Form from "./scence/form";
import Bar from "./scence/bar";
import Login from "./scence/login/index.jsx";
import { Fragment } from 'react'
import { ProtectedRoute } from "./scence/route/protected";

function App() {
  // return (
  //   <div className="app">
  //     <SideBar />
  //     <main className="content">
  //       <Routes>
  //         <Route path="/" element={<Login />} />
  //         <Route path="/gateKeeper" element={<Team />} />
  //         <Route path="/user" element={<Contact />} />
  //         <Route path="/from" element={<Form />} />
  //       </Routes>

  //     </main>
  //   </div>
  // );

  return (
    // <div>
    <Routes>
      <Route
        path="/"
        element={
          <Fragment>
            <div className="forLogin">
              <Login />
            </div>
          </Fragment>
        }
      />
      <Route
        path="/*"
        element={
          <div className="app">
            <SideBar />
            <main className="content">
              <Routes>
                <Route element={<ProtectedRoute />}>
                  {/* <Route path="/" element={<Login />} /> */}
                  <Route path="/gateKeeper" element={<Team />} />
                  <Route path="/user" element={<Contact />} />
                  <Route path="/form" element={<Form />} />
                </Route>
              </Routes>
            </main>
          </div >
        }
      />
    </Routes >

    // </div>
  )
}

export default App;
